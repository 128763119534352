import { Card, CardBody } from "@material-tailwind/react";
import React from "react";
import { FaFighterJet } from "react-icons/fa";
import { IoFootball } from "react-icons/io5";
import { MdOutlineSportsCricket, MdSportsBasketball } from "react-icons/md";
import { FaBasketball } from "react-icons/fa6";
import {
  Link,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";

const navLinks = [
  // {
  //   name: "Soccer",
  //   icon: <IoFootball />,
  //   path: "/football",
  //   path2: "/",
  // },
  // {
  //   name: "CS:GO",
  //   icon: <MdOutlineSportsCricket />,
  //   path: "/csgo",
  // },
  // {
  //   name: "Valorant",
  //   icon: <FaFighterJet />,
  //   path: "/valorant",
  // },
  //------------------------updated code---------------------------------------------
  {
    name: "All Matches",
    icon: <MdSportsBasketball />,
    path: "/",
    value: "all",
  },
  // {
  //   name: "All Matches",
  //   icon: <IoFootball />,
  //   path: "/test",
  //   value: "all",
  // },
  {
    name: "Soccer",
    icon: <IoFootball />,
    // path: "?game=soccer",
    path: "/soccer",
    value: "soccer",
  },
  {
    name: "Cricket",
    icon: <MdOutlineSportsCricket />,
    path: "/cricket",
    value: "cricket",
  },
  // {
  //   name: "CS:GO",
  //   icon: <MdOutlineSportsCricket />,
  //   path: "?game=csgo",
  // },
  // {
  //   name: "Valorant",
  //   icon: <FaFighterJet />,
  //   path: "/valorant",
  //   value: "valorant",
  // },
  {
    name: "NBA",
    icon: <FaBasketball />,
    path: "/nba",
    value: "nba",
  },
];

const SubHeader = () => {
  // const [searchParams] = useSearchParams();
  // const game = searchParams.get("game") || "all";
  const { game ="all" } = useParams()
  const isCurrentPath = (path) => (game === path ? true : false);

  // const { pathname } = useLocation();
  // const isCurrentPath = (path) => (pathname === path ? true : false);

  return (
    <div>
      <Card className="bg-lightPrimary dark:bg-primary">
        <CardBody className="p-4">
          <ul className="flex gap-2 md:gap-4 items-center lg:gap-6">
            {navLinks.map((link, index) => (
              <p
                key={index}
                as="li"
                variant="small"
                className={`cursor-pointer p-1 font-normal text-md hover:text-secondary ${
                  isCurrentPath(link?.value)
                    ? "text-secondary dark:text-primaryLighter"
                    : "text-gray-900 dark:text-gray-200"
                }`}
              >
                <Link to={link?.path} className="flex items-center gap-1 text-xs md:text-base">
                  {/* <IoFootball /> */}
                  {link?.icon}
                  {link?.name}
                </Link>
              </p>
            ))}
          </ul>
        </CardBody>
      </Card>
    </div>
  );
};

export default SubHeader;
