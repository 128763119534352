// import {
//     Dialog,
//     DialogBody,
//     DialogHeader,
//   } from "@material-tailwind/react";
//   import React from "react";
//   import { MdOutlineClose } from "react-icons/md";

//   const SoccerPredictionEditModal = ({ open, handleOpen, data }) => {
//     console.log(data)
//     return (
//       <Dialog
//         open={open}
//         handler={handleOpen}
//         className=" p-6 bg-lightSecondary dark:bg-primary max-h-[90vh] overflow-auto scroll-hidden"
//         size="md"
//       >
//         <DialogHeader className="text-black dark:text-white text-center">
//           <div className="w-full flex justify-between items-center">
//             <p> Our Prediction</p>
//             <div>
//               <button
//                 className=" bg-secondary/10 text-secondary dark:bg-lightPrimary/10 dark:text-lightPrimary p-2 w-12 h-12 rounded-full flex items-center justify-center"
//                 onClick={handleOpen}
//               >
//                 <MdOutlineClose />
//               </button>
//             </div>
//           </div>
//         </DialogHeader>
//         <DialogBody className="p-2 md:p-4">
//           <div className="lg:px-6 px-2 py-6 shadow bg-lightSecondary dark:bg-lightPrimary/10 rounded-xl">
//             {/* top part of the code */}
//             <div className="flex justify-between items-center gap-4">
//               <div className="flex flex-col justify-center items-center gap-2">
//                 <img
//                   className="lg:w-14 w-8 h-8 lg:h-14"
//                   src={data?.T1Logo}
//                   alt={`${data?.HomeTeam} logo`}
//                 />
//                 <p className="font-bold text-xs md:text-lg text-center text-secondary dark:text-lightPrimary">
//                   {data?.HomeTeam}
//                 </p>
//               </div>

//               <div>
//                 <p className="text-lg md:text-4xl font-semibold text-secondary dark:text-lightPrimary">
//                   {data?.Time}
//                 </p>
//                 <p className="text-xs md:text-base text-secondary dark:text-lightPrimary">
//                   {data?.Date}
//                 </p>
//               </div>

//               <div className="flex flex-col justify-center items-center gap-2">
//                 <img
//                   className="lg:w-14 w-8 h-8 lg:h-14"
//                   src={data?.T2Logo}
//                   alt={`${data?.AwayTeam} logo`}
//                 />
//                 <p className="font-bold text-xs md:text-lg  text-center text-secondary dark:text-lightPrimary">
//                   {data?.AwayTeam}
//                 </p>
//               </div>
//             </div>

//             <div className="w-full h-[1px] dark:bg-secondary/50 my-8"></div>

//             {/* odds part of the code */}
//             <div className="">
//               <h3 className="text-xl md:2xl font-semibold text-green-700">
//                 Odds
//               </h3>

//               <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8 mt-4">
//                 <div className="flex justify-between border border-secondary/10 bg-primary/5 dark:border-lightSecondary/5 dark:bg-secondary/20 rounded-sm p-2">
//                   <p className="text-sm md:text-base text-secondary dark:text-lightPrimary font-semibold">
//                     {data?.HomeTeam}
//                   </p>
//                   <input type="number" className="text-xs md:text-sm font-semibold text-secondary dark:text-lightSecondary bg-primary/5 dark:bg-secondary/20" defaultValue={data?.HomeOdds === "nan" ? "" : data?.HomeOdds}/>

//                 </div>
//                 <div className="flex justify-between border border-secondary/10 bg-primary/5 dark:border-lightSecondary/5 dark:bg-secondary/20 rounded-sm p-2">
//                   <p className="text-sm md:text-base text-secondary dark:text-lightPrimary font-semibold">
//                     Draw
//                   </p>
//                   <input type='number' className="text-xs md:text-sm font-semibold text-secondary dark:text-lightSecondary bg-primary/5 dark:bg-secondary/20" defaultValue={data?.DrawOdds === "nan" ? "" : data?.DrawOdds}/>

//                 </div>
//                 <div className="flex justify-between border border-secondary/10 bg-primary/5 dark:border-lightSecondary/5 dark:bg-secondary/20 rounded-sm p-2">
//                   <p className="text-sm md:text-base text-secondary dark:text-lightPrimary font-semibold">
//                     {data?.AwayTeam}
//                   </p>
//                   <input type="number" className="text-xs md:text-sm font-semibold text-secondary dark:text-lightSecondary bg-primary/5 dark:bg-secondary/20" defaultValue={data?.AwayOdds === "nan" ? "" : data?.AwayOdds}/>

//                 </div>
//               </div>
//             </div>

//             {data?.HandicapMainpage ? (
//               <div className="mt-4 w-full md:w-3/4 lg:w-3/4 mx-auto">
//                 <div className="flex justify-between border border-secondary/10 bg-primary/5 dark:border-lightSecondary/5 dark:bg-secondary/20 rounded-sm p-2">
//                   <p className="text-sm md:text-base text-secondary dark:text-lightPrimary font-semibold">
//                     {data?.Prediction}
//                   </p>
//                   <p className="text-xs md:text-sm font-semibold text-secondary dark:text-lightSecondary">
//                     {data?.PredictedOdds}
//                   </p>
//                 </div>
//               </div>
//             ) : null}

//             {/* second part of the code */}
//             <div className="card mt-16 px-8 py-6">
//               <h4 className="text-sm lg:text-xl text-center text-black dark:text-white">
//                 We Predict :{" "}
//                 <input type="text" className="font-bold tracking-widest text-green-800 dark:text-green-500 bg-primary/5 dark:bg-secondary/20" defaultValue={data?.Prediction}/>

//               </h4>
//             </div>
//           </div>
//         </DialogBody>
//       </Dialog>
//     );
//   };

//   export default SoccerPredictionEditModal;

import { Button, Dialog, DialogBody, DialogHeader } from "@material-tailwind/react";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { MdOutlineClose } from "react-icons/md";
import { useUpdateMatch } from "../../hooks/useUpdateMatch";
import toast from "react-hot-toast";

const SoccerPredictionEditModal = ({ open, handleOpen, data,setPredictionEditOpen }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  // Update form when `data` changes
  const { mutateAsync: updateMatch, isLoading,isSuccess, isError } = useUpdateMatch();
  useEffect(() => {
    if (data) {
      reset({
        HomeOdds: data?.HomeOdds === "nan" ? "" : data?.HomeOdds,
        DrawOdds: data?.DrawOdds === "nan" ? "" : data?.DrawOdds,
        AwayOdds: data?.AwayOdds === "nan" ? "" : data?.AwayOdds,
        Prediction: data?.Prediction || "",
        // Date: data?.Date || "",
        // Time: data?.Time || "",
      });
    }
  }, [data, reset]);

  const onSubmit = async (formData) => {
    try {
      const response = await updateMatch({ id: data?.MatchID, data: formData });
      if(response.success){
      toast.success("Match updated successfully");
      setPredictionEditOpen(false);
        
      }
    } catch (error) {
      toast.error("Something went wrong!");
    }
  };

  return (
    <Dialog
      open={open}
      handler={handleOpen}
      className="p-6 bg-lightSecondary dark:bg-primary max-h-[90vh] overflow-auto scroll-hidden"
      size="md"
    >
      <DialogHeader className="text-black dark:text-white text-center">
        <div className="w-full flex justify-between items-center">
          <p>Our Prediction</p>
          <button
            className="bg-secondary/10 text-secondary dark:bg-lightPrimary/10 dark:text-lightPrimary p-2 w-12 h-12 rounded-full flex items-center justify-center"
            onClick={handleOpen}
          >
            <MdOutlineClose />
          </button>
        </div>
      </DialogHeader>
      <DialogBody className="p-2 md:p-4">
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* Top part */}
          <div className="flex justify-between items-center gap-4">
            <div className="flex flex-col justify-center items-center gap-2">
              <img
                className="lg:w-14 w-8 h-8 lg:h-14"
                src={data?.T1Logo}
                alt={`${data?.HomeTeam} logo`}
              />
              <p className="font-bold text-xs md:text-lg text-secondary dark:text-lightPrimary">
                {data?.HomeTeam}
              </p>
            </div>

            <div className="flex flex-col justify-center items-center">
              <div>
                <p className="w-full text-xs text-center md:text-base text-secondary dark:text-lightPrimary bg-transparent">{data?.Date}</p>
              </div>
              <div>
                <p className="w-full text-center text-lg md:text-4xl font-semibold text-secondary dark:text-lightPrimary bg-transparent">{data?.Time}</p>
              </div>
            </div>

            <div className="flex flex-col justify-center items-center gap-2">
              <img
                className="lg:w-14 w-8 h-8 lg:h-14"
                src={data?.T2Logo}
                alt={`${data?.AwayTeam} logo`}
              />
              <p className="font-bold text-xs md:text-lg text-secondary dark:text-lightPrimary">
                {data?.AwayTeam}
              </p>
            </div>
          </div>

          <div className="w-full h-[1px] dark:bg-secondary/50 my-8"></div>

          {/* Odds Section */}
          <div>
            <h3 className="text-xl font-semibold text-green-700">Odds</h3>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4">
              <div>
                <div className="flex gap-5 border items-center p-2">
                  <p className="text-sm font-semibold text-secondary dark:text-lightPrimary">
                    {data?.HomeTeam}
                  </p>
                  <input
                    type="number"
                    step="any"
                    {...register("HomeOdds", {
                      required: "Home Odds is required",
                    })}
                    className="w-full text-sm p-2 font-semibold text-secondary dark:text-lightPrimary bg-primary/5 dark:bg-secondary/20"
                  />
                </div>
                {errors.HomeOdds && (
                  <p className="text-red-500 text-xs mt-2 font-semibold">
                    {errors.HomeOdds.message}
                  </p>
                )}
              </div>
              <div>
                <div className="flex gap-5 border items-center p-2">
                  <label className="text-sm font-semibold text-secondary dark:text-lightPrimary">
                    Draw
                  </label>
                  <input
                    type="number"
                    step="any"
                    {...register("DrawOdds", {
                      required: "Draw Odds is required",
                    })}
                    className="w-full text-sm p-2 font-semibold text-secondary dark:text-lightPrimary bg-primary/5 dark:bg-secondary/20"
                  />
                </div>
                {errors.DrawOdds && (
                  <p className="text-red-500 text-xs mt-2 font-semibold">
                    {errors.DrawOdds.message}
                  </p>
                )}
              </div>
              <div>
                <div className=" flex gap-5 items-center border p-2">
                  <label className="text-sm font-semibold text-secondary dark:text-lightPrimary">
                    {data?.AwayTeam}:{" "}
                  </label>
                  <input
                    type="number"
                    step="any"
                    {...register("AwayOdds", {
                      required: "Away Odds is required",
                    })}
                    className="w-full text-sm p-2 font-semibold text-secondary dark:text-lightPrimary bg-primary/5 dark:bg-secondary/20"
                  />
                </div>
                {errors.AwayOdds && (
                  <p className="text-red-500 text-xs font-semibold mt-2">
                    {errors.AwayOdds.message}
                  </p>
                )}
              </div>
            </div>
          </div>
          {data?.HandicapMainpage ? (
            <div className="mt-4 w-full md:w-3/4 lg:w-3/4 mx-auto">
              <div className="flex justify-between border border-secondary/10 bg-primary/5 dark:border-lightSecondary/5 dark:bg-secondary/20 rounded-sm p-2">
                <p className="text-sm md:text-base text-secondary dark:text-lightPrimary font-semibold">
                  {data?.Prediction}
                </p>
                <p className="text-xs md:text-sm font-semibold text-secondary dark:text-lightSecondary">
                  {data?.PredictedOdds}
                </p>
              </div>
            </div>
          ) : null}
          {/* Prediction */}
          <div className="mt-6">
            <label className="text-sm font-semibold text-secondary dark:text-lightPrimary">
              Prediction:
            </label>
            <input
              type="text"
              {...register("Prediction", {
                required: "Prediction is required",
              })}
              className="w-full p-2 rounded-md bg-primary/5 dark:bg-secondary/20 text-secondary dark:text-lightPrimary"
            />
            {errors.Prediction && (
              <p className="text-red-500 text-xs mt-2 font-semibold">
                {errors.Prediction.message}
              </p>
            )}
          </div>

          <div className="flex justify-end mt-6">
            <Button
              type="submit"
              onLoad={isLoading}
              className="px-6 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700"
            >
              Submit
            </Button>
          </div>
        </form>
      </DialogBody>
    </Dialog>
  );
};

export default SoccerPredictionEditModal;
