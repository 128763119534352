import { FaTrash } from "react-icons/fa";
import Loading from "../../components/shared/Loading/Loading";
import useAllBlogs from "../../hooks/useAllBlogs";
import { IconButton, Tooltip } from "@material-tailwind/react";
import DataTable from "../../components/shared/DataTable";
import { useState } from "react";
import { MdEditSquare } from "react-icons/md";
import DeleteDialog from "../../components/admin/DeleteDialog";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { fetcher } from "../../utils/authFetch";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";

const ManageBlogs = () => {
  // get all the blogs
  const queryClient = useQueryClient();
  const [isDraft, setIsDraft] = useState(false);
  const { allBlogsLoading, allBlogs } = useAllBlogs(isDraft);
  const [page, setPage] = useState(1);
  const [selected, setSelected] = useState({});
  const [deleteOpen, setDeleteOpen] = useState(false);
  const limit = 10;
  // delete a blog
  const { mutateAsync: deletePost, isLoading: deleteLoading } = useMutation({
    mutationFn: async (recordId) => fetcher.delete(`/api/blogs/${recordId}`),
    onSuccess: (res) => {
      queryClient.invalidateQueries(["user"]);
      toast.success(res?.data?.message || "Post deleted successfully!");
      setSelected({});
      setDeleteOpen(false);
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message || "Something went wrong!");
    },
  });

  const handleDelete = async () => {
    await deletePost(selected?._id);
  };

  // table column
  const columns = [
    {
      field: "Serial",
      headerName: "Serial",
    },
    {
      field: "Title",
      headerName: "Title",
      render: (row) => (
        <Tooltip content={row?.Title}>
          <span className="font-semibold text-sm">
            {row?.Title?.length >= 45
              ? row?.Title?.slice(0, 45) + "..."
              : row?.Title}
          </span>
        </Tooltip>
      ),
    },
    {
      field: "Author",
      headerName: "Author",
      render: (row) => (
        <span className="text-sm font-medium">{row?.Author}</span>
      ),
    },
    {
      field: "Category",
      headerName: "Category",
      render: (row) => (
        <span className="text-sm font-medium">{row?.Category}</span>
      ),
    },
    {
      field: "Tags",
      headerName: "Tags",
      render: (row) => (
        <Tooltip className="max-w-[400px]" content={row?.Tags}>
          <span className="text-sm font-medium">
            {row?.Tags?.length >= 40
              ? row?.Tags?.slice(0, 40) + "..."
              : row?.Tags}
          </span>
        </Tooltip>
      ),
    },
    {
      field: "Draft",
      headerName: "Status",
      render: (row) => (
        <span className="text-sm font-medium">
          {row?.Draft ? (
            <span className="bg-[#fff4ea] text-orange-900 font-semibold px-1 py-0.5">
              Unpublished
            </span>
          ) : (
            <span className="bg-[#eafff2] text-green-800 font-semibold px-1 py-0.5">
              Published
            </span>
          )}
        </span>
      ),
    },
    {
      field: "CreatedAt",
      headerName: "Creation date",
      render: (row) => (
        <span className="text-sm font-medium w-[300px]">
          {row?.createdAt?.split("T")[0]}
        </span>
      ),
    },
    {
      field: "UpdatedAt",
      headerName: "Last update",
      render: (row) => (
        <div>
          <div className="text-sm font-medium w-[300px]">
            {row?.createdAt === row?.updatedAt ? (
              ""
            ) : (
              <div className="flex items-center space-x-2">
                <p>{row?.updatedAt?.split("T")[0]} </p>
                <div className="bg-green-800 px-2 py-1 rounded-lg text-white">Updated</div>
              </div>
            )}
          </div>
        </div>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      render: (row) => (
        <span>
          {/* update button */}
          <Link to={`/admin/updateBlog/${row?.Slug}`}>
            <IconButton
              title="Update"
              color="blue"
              variant="gradient"
              className="text-white text-md h-8 w-8 rounded-full p-1"
            >
              <MdEditSquare />
            </IconButton>
          </Link>

          {/* delete button */}
          <IconButton
            title="Delete"
            color="red"
            variant="gradient"
            className="text-white text-md h-8 w-8 rounded-full p-1 ml-3"
            onClick={() => {
              setSelected(row);
              setDeleteOpen(true);
            }}
          >
            <FaTrash />
          </IconButton>
        </span>
      ),
    },
  ];

  // conditional loading
  if (allBlogsLoading) {
    return <Loading className={"h-[600px]"} />;
  }

  return (
    <div>
      <div className="w-full flex justify-between items-center">
        <h2 className="text-3xl font-bold">Manage blogs</h2>
        <div className="flex items-center space-x-3">
          <button
            onClick={() => setIsDraft(true)}
            className={`bg-primary text-white px-3 py-1 rounded font-semibold hover:bg-primaryLighter duration-500 ${
              isDraft && "bg-primaryLighter"
            }`}
          >
            Show Draft
          </button>
          <button
            onClick={() => setIsDraft(false)}
            className={`bg-primary text-white px-3 py-1 rounded font-semibold hover:bg-primaryLighter duration-500 ${
              !isDraft && "bg-primaryLighter"
            }`}
          >
            Show Published
          </button>
        </div>
        {/* create new blog button */}
        <Link
          to={"/admin/addBlog"}
          className="bg-primary text-white px-3 py-1 rounded font-semibold hover:bg-primaryLighter duration-500"
        >
          Add New Blog
        </Link>
      </div>

      {/* table to show all the data */}
      <div className="mt-6">
        <DataTable
          isLoading={allBlogsLoading}
          rows={
            allBlogs?.data
              ?.slice((page - 1) * limit, limit * page)
              ?.map((item, index) => ({
                recordId: item?.id,
                Serial: index + 1 + (page - 1) * limit,
                ...item,
              })) || []
          }
          columns={columns}
          total={allBlogs?.data?.records?.length}
          pagination={true}
          paginationOptions={{
            page,
            setPage,
            limit,
          }}
        />
      </div>

      {/* Delete Dialog */}
      <DeleteDialog
        open={deleteOpen}
        handler={() => setDeleteOpen(!deleteOpen)}
        title="Delete Post"
        description="Are you sure you want to delete the post?"
        handleDelete={handleDelete}
        loading={deleteLoading}
      />
    </div>
  );
};

export default ManageBlogs;
