// FootBall
import FootballBottomAllComponent from "../components/football/FootballBottomAllComponent";
import FootballDetails from "../components/football/FootballDetails";
import Football from "../pages/Football";

// Csgo
import CsgoBottomAllComponent from "../components/csgo/CsgoBottomAllComponent";
import CsgoDetails from "../components/csgo/CsgoDetails";
import Csgo from "../pages/Csgo";

// Valorant
import ValorantBottomAllComponent from "../components/valorant/ValorantBottomAllComponent";
import Valorant from "../pages/Valorant";

// Others
import EmailVerify from "../components/Auth/EmailVerify";
import ForgotPassword from "../components/Auth/ForgotPassword";
import ForgotPasswordOTP from "../components/Auth/ForgotPasswordOTP";
import GoogleCallback from "../components/Auth/GoogleCallback";
import ResetPassword from "../components/Auth/ResetPassword";
import Account from "../components/account/Account";
import ChatbotLead from "../components/admin/AdminDashboard/ChatbotLead";
import PaidUsers from "../components/admin/AdminDashboard/PaidUsers";
import AdminError from "../components/admin/AdminError";
import MultiChainMiddle from "../components/multiChain/MultiChainMiddle";
import NewsDetails from "../components/news/NewsDetails";
import Payment from "../components/payment/Payment";
import Common from "../components/shared/Common";
import ValorantDetails from "../components/valorant/ValorantDetails";
import AdminLayout from "../layout/AdminLayout";
import AuthLayout from "../layout/AuthLayout";
import FeedBack from "../pages/FeedBack";
import HandicapPrediction from "../pages/HandicapPrediction";
import Login from "../pages/Login";
import MultiChainPrediction from "../pages/MultiChainPrediction";
import News from "../pages/News";
import OurAccuracy from "../pages/OurAccuracy";
import Pricing from "../pages/Pricing";
import ROI from "../pages/ROI";
import SignUp from "../pages/SignUp";
import UserGuide from "../pages/UserGuide";
import AdminCSGO from "../pages/admin/AdminCSGO";
import AdminDashboardV2 from "../pages/admin/AdminDashboardV2";
import AdminSoccer from "../pages/admin/AdminSoccer";
import AdminValorant from "../pages/admin/AdminValorant";
import CreateBlog from "../pages/admin/CreateBlog";
import ManageBlogs from "../pages/admin/ManageBlogs";
import NewsletterLists from "../pages/admin/NewsletterLists";
import UpdateBlog from "../pages/admin/UpdateBlog";
import Users from "../pages/admin/Users";
import Main from "./Main";
import AllMatches from "../pages/AllMatches";
import AllMatchesBottomComponent from "../components/allMatches/AllMatchesBottomComponent";
import HandicapAllComponentV2 from "../components/handicap/HandicapAllComponentV2.jsx";
import AccuracyV2 from "../components/ourAccuracy/AccuracyV2.jsx";
import AdminHandicap from "../pages/admin/AdminHandicap.jsx";
import AdminNba from "../pages/admin/AdminNba.jsx";
import AllNbaMatch from "../components/allNbaMatch/AllNbaMatch.jsx";


const { createBrowserRouter } = require("react-router-dom");

const router = createBrowserRouter([
  // User Routes
  {
    path: "/",
    element: <Main />,
    children: [
      // {
      //   path: "/",
      //   element: <Football />,
      //   children: [
      //     {
      //       path: "/",
      //       element: <FootballBottomAllComponent />,
      //     },
      //     {
      //       path: "/football/:id",
      //       element: <FootballDetails />,
      //     },
      //   ],
      // },
      {
        path: "/",
        element: <AllMatches />,
        children:[
          {
            path: "/",
            element: <AllMatchesBottomComponent />,
          },
          {
            path:  "/:game",
            element: <AllMatchesBottomComponent />,
          }
          // {
          //   path: "/handicap",
          //   element: <HandicapAllComponentV2 />,
          // }
        ]
      },
      {
        path: "/nba",
        element: <AllNbaMatch />,

      },
      {
        path: "/account",
        element: <Account />,
      },
      // {
      //   path: "/football",
      //   element: <Football />,
      //   children: [
      //     {
      //       path: "/football",
      //       element: <FootballBottomAllComponent />,
      //     },
      //     {
      //       path: "/football/:id",
      //       element: <FootballDetails />,
      //     },
      //   ],
      // },
      // {
      //   path: "/csgo",
      //   element: <Csgo />,
      //   children: [
      //     {
      //       path: "/csgo",
      //       element: <CsgoBottomAllComponent />,
      //     },
      //     {
      //       path: "/csgo/:id",
      //       element: <CsgoDetails />,
      //     },
      //   ],
      // },
      // {
      //   path: "/valorant",
      //   element: <Valorant />,
      //   children: [
      //     {
      //       path: "/valorant",
      //       element: <ValorantBottomAllComponent />,
      //     },
      //     {
      //       path: "/valorant/:id",
      //       element: <ValorantDetails />,
      //     },
      //   ],
      // },
      {
        path: "/news",
        element: <News />,
        children: [
          {
            path: "/news/:id",
            element: <NewsDetails />,
          },
        ],
      },
      {
        path: "/multi-prediction",
        element: <MultiChainPrediction />,
      },
      {
        path: "/multi-prediction/multichaindetails",
        element: <MultiChainMiddle />,
      },
      // {
      //   path: "/handicap-prediction",
      //   element: <HandicapPrediction />,
      // },
      {
        path: "/handicap-prediction",
        element: <HandicapAllComponentV2 />,
      },
      {
        path: "/roi",
        element: <ROI />,
      },
      {
        path: "/feedback",
        element: <FeedBack />,
      },
      {
        path: "/user-guide",
        element: <UserGuide />,
      },
      {
        path: "/our-accuracy",
        element: <AccuracyV2 />,
      },
      // {
      //   path: "/our-accuracy-v2",
      //   element: <AccuracyV2 />,
      // },

      {
        path: "*",
        element: <Common />,
      },
    ],
  },

  // Admin Routes
  {
    path: "/admin",
    element: <AdminLayout />,
    children: [
      {
        path: "/admin",
        element: <AdminDashboardV2 />,
      },
      // {
      //   path: "/admin/dashboard",
      //   element: <AdminDashboard />,
      // },
      {
        path: "/admin/matches/cs-go",
        element: <AdminCSGO />,
      },
      {
        path: "/admin/matches/soccer",
        element: <AdminSoccer />,
      },
      {
        path: "/admin/matches/nba",
        element: <AdminNba />,
      },
      {
        path: "/admin/matches/handicap",
        element: <AdminHandicap />,
      },
      {
        path: "/admin/matches/valorant",
        element: <AdminValorant />,
      },
      {
        path: "/admin/users",
        element: <Users />,
      },
      {
        path: "/admin/paid-users",
        element: <PaidUsers />,
      },
      {
        path: "/admin/addBlog",
        element: <CreateBlog />,
      },
      {
        path: "/admin/manageBlogs",
        element: <ManageBlogs />,
      },
      {
        path: "/admin/updateBlog/:id",
        element: <UpdateBlog />,
      },
      {
        path: "/admin/updateBlog/:id",
        element: <UpdateBlog />,
      },
      {
        path: "/admin/newsletterLists",
        element: <NewsletterLists />,
      },
      {
        path: "/admin/lead",
        element: <ChatbotLead />,
      },
      {
        path: "*",
        element: <AdminError />,
      },
    ],
  },

  // Auth Routes
  {
    path: "/",
    element: <AuthLayout />,
    children: [
      { path: "/login", element: <Login /> },
      { path: "/signup", element: <SignUp /> },
      { path: "/email-verify", element: <EmailVerify /> },
      { path: "/google-callback", element: <GoogleCallback /> },
      { path: "/forgot-password", element: <ForgotPassword /> },
      { path: "/forgot-password-otp", element: <ForgotPasswordOTP /> },
      { path: "/reset-password", element: <ResetPassword /> },
    ],
  },

  // General Routes
  {
    path: "/pricing",
    element: <Pricing />,
  },
  {
    path: "/payment",
    element: <Payment />,
  },
]);
export default router;
