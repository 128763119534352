import { Card, CardBody, Chip } from "@material-tailwind/react";
import React, { useEffect, useMemo, useState } from "react";
import { useAllMatches } from "../../hooks/useAllMatches";
import { format } from "date-fns";
import Loading from "../shared/Loading/Loading";
import SoccerCard from "../soccer/SoccerCard";
import CricketCardV2 from "../cricket/CricketCardV2";
import ValorantCardV2 from "../valorant/ValorantCardV2";
import { getTimeUntilMatchStart } from "../../utils/getTimeUntilMatchStart";
import { useParams, useSearchParams } from "react-router-dom";

const navList = [
  {
    name: "All",
    param: "all",
  },
  {
    name: "Live",
    param: "live",
  },
  {
    name: "Finished",
    param: "finished",
  },
  {
    name: "Schedule",
    param: "schedule",
  },
];

const AllMatchesBottomComponent = () => {
  const {game} = useParams();

  // console.log(gamename);

  // const [searchParams] = useSearchParams();
  // const game = searchParams.get("game");

  const [filter, setFilter] = React.useState("all");
  const [date, setDate] = React.useState(new Date());

  const { data, isLoading } = useAllMatches({ date, filter, game });

  const handleFilterClick = (filterParam) => {
    setFilter(filterParam);
  };

  const [timeUntilMatchStarts, setTimeUntilMatchStarts] = useState([]);
  useEffect(() => {
    if (data?.data?.length > 0) {
      const timeUntilMatches = data.data.map((game) => {
        const { hours, minutes } = getTimeUntilMatchStart(
          game.fields.Date,
          game.fields.Time
        );
        game.fields.startHours = hours;
        game.fields.startMinutes = minutes;
        return { id: game.id, hours, minutes };
      });

      setTimeUntilMatchStarts(timeUntilMatches);
    }
  }, [data]);

  // Group matches by date and sort by time
  const groupedData = useMemo(() => {
    const groupedMatches = data?.data?.reduce((groups, item) => {
      const date = item.fields.Date;
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(item);
      return groups;
    }, {});

    // Sort each group by time
    for (const date in groupedMatches) {
      groupedMatches[date].sort((a, b) => {
        const timeA = new Date(`${a.fields.Date}T${a.fields.Time}`);
        const timeB = new Date(`${b.fields.Date}T${b.fields.Time}`);
        return timeA - timeB;
      });
    }

    return groupedMatches;
  }, [data]);

  // Get sorted dates
  const sortedDates = useMemo(() => {
    if (!groupedData) return [];
    return Object.keys(groupedData).sort((a, b) => new Date(a) - new Date(b));
  }, [groupedData]);

  return (
    <>
      <Card className="bg-lightPrimary dark:bg-primary h-[calc(100vh-152px)]  md:h-[calc(100vh-170px)] py-10">
        <CardBody className="overflow-y-scroll scroll-hidden text-justify text-gray-200 p-0">
          <div className="flex md:flex-row flex-col justify-between items-start gap-4 sticky top-0 bg-lightPrimary dark:bg-primary p-4  md:py-0 md:px-6 rounded-xl z-20">
            <div className="overflow-y-scroll scroll-hidden text-justify text-gray-200">
              <div className="flex justify-between items-center ">
                <div className="flex gap-2 ">
                  {navList.map((item, i) => (
                    <button
                      key={i}
                      className={`text-[10px] py-1 px-2.5 rounded-lg ${
                        filter === item.param
                          ? "bg-primaryLighter text-black"
                          : "bg-lightSecondary dark:bg-secondary dark:text-white text-black"
                      }`}
                      onClick={() => handleFilterClick(item.param)}
                    >
                      {item.name}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {isLoading ? (
            <Loading className="h-40 " />
          ) : (
            <div className="md:px-6 px-1 md:pb-6 pb-4">
              {sortedDates.length > 0 ? (
                sortedDates.map((date, i) => (
                  <div key={i}>
                    <Chip
                      variant="filled"
                      className="max-w-min mt-12 bg-lightSecondary dark:bg-secondary text-black dark:text-white"
                      value={`Date: ${format(new Date(date), "dd MMMM, yyyy")}`}
                    />
                    <div className="mt-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-5">
                      {groupedData[date].map((item, j) => (
                        <div key={j}>
                          {item?.fields?.gameType === "Soccer" ? (
                            <SoccerCard data={item} />
                          ) : item?.fields?.gameType === "Cricket" ? (
                            <CricketCardV2 data={item} />
                          ) : (
                            <ValorantCardV2 data={item} />
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                ))
              ) : (
                <p className="dark:text-lightPrimary text-black text-center pt-20">
                  No matches are available.
                </p>
              )}
            </div>
          )}
        </CardBody>
      </Card>
    </>
  );
};

export default AllMatchesBottomComponent;
