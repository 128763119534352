import axios from "axios";
import Cookies from "js-cookie";
import config from "./config";

export const rapidFetch = axios.create({});
rapidFetch.interceptors.request.use(
  (axiosConfig) => {
    axiosConfig.headers = {
      "X-RapidAPI-Key": "b6e89817d6msh36107de73277139p116779jsne307fb015e33",
      "X-RapidAPI-Host": "api-football-v1.p.rapidapi.com",
    };
    return axiosConfig;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Fetcher for Airtable API
export const fetcher = axios.create({
  baseURL: config.server_url,
});

fetcher.interceptors.request.use(
  (axiosConfig) => {
    axiosConfig.headers = {
      Authorization: `Bearer ${Cookies.get("token")}`,
    };
    return axiosConfig;
  },
  (error) => {
    return Promise.reject(error);
  }
);

fetcher.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status === 401 || error.response.status === 403) {
      Cookies.remove("token");
      Cookies.remove("user");
      Cookies.remove("email");
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);
