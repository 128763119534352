import { Button, Typography } from "@material-tailwind/react";
import { useForm, Controller } from "react-hook-form";
import { useUpdateHandicapData } from "../../hooks/useUpdateHandicapData";
import toast from "react-hot-toast";
export default function HandicapTable({
  tableHead,
  rows,
  matchId,
  tag,
  setEditPredictionOpen,
}) {
  const {
    mutateAsync: updateHandicap,
    isLoading,
    isSuccess,
    isError,
  } = useUpdateHandicapData();
  const { control, handleSubmit } = useForm({
    defaultValues: {
      rows: rows.map((row) => ({ ...row })),
    },
  });

  const onSubmit = async (data) => {
    try {
      const res = await updateHandicap({
        matchId,
        data: [...data.rows, { tag }],
      });
      if (res.success === true) {
        toast.success("Handicap updated successfully");
        setEditPredictionOpen(false);
      }
    }catch(error){
      toast.error("Something went wrong");
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <table className="w-full min-w-max table-auto text-left">
        <thead>
          <tr>
            {tableHead.map((head) => (
              <th key={head} className="pt-0 px-4 pb-4">
                <Typography
                  variant="small"
                  className="font-normal leading-none opacity-70 dark:text-gray-100 text-black"
                >
                  {head}
                </Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row, rowIndex) => {
            const isLast = rowIndex === rows.length - 1;
            const classes = isLast ? "px-4 py-1" : "px-4 py-1";

            return (
              <tr key={row.name}>
                <td className={classes}>
                  <Typography
                    variant="small"
                    className="font-normal opacity-70 dark:text-gray-100 text-black"
                  >
                    {row.name}
                  </Typography>
                </td>
                <td className={classes}>
                  <Controller
                    name={`rows.${rowIndex}.prediction`}
                    control={control}
                    render={({ field }) => (
                      <input
                        {...field}
                        className="w-full bg-transparent border-none focus:outline-none dark:text-gray-100 text-black bg-[#bcc2ce] dark:bg-[#1a213f] px-2 py-1"
                      />
                    )}
                  />
                </td>
                <td className={classes}>
                  <Controller
                    name={`rows.${rowIndex}.odds`}
                    control={control}
                    render={({ field }) => (
                      <input
                        {...field}
                        type="number"
                        step="0.01"
                        className="w-full bg-transparent border-none focus:outline-none dark:text-gray-100 text-black bg-[#bcc2ce]  dark:bg-[#1a213f] px-2 py-1"
                      />
                    )}
                  />
                </td>
                <td className={classes}>
                  <Typography
                    variant="small"
                    className={
                      row.status === "Won"
                        ? "dark:text-green-700 text-green-900 font-semibold"
                        : row.status === "Lost"
                        ? "dark:text-red-700 text-red-900 font-semibold"
                        : row.status === "Not updated yet"
                        ? "dark:text-yellow-700 text-yellow-900 font-semibold"
                        : "font-normal opacity-70 dark:text-gray-100 text-black"
                    }
                  >
                    {row.status || ""}
                  </Typography>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="flex itmes-center justify-start mt-4 ml-4">
        <Button
          type="submit"
          className="px-4 py-1 bg-blue-500 text-[12px] text-white font-semibold rounded-md hover:bg-blue-600 tracking-widest"
        >
          UPDATE
        </Button>
      </div>
    </form>
  );
}
