import { useQuery } from "@tanstack/react-query";
import { fetcher } from "../utils/authFetch";


const useAllBlogs = (isDraft) => {
    const { isLoading: allBlogsLoading, data: allBlogs, refetch: allBlogsRefetch } = useQuery({
        queryKey: ["all-blogs", isDraft],
        queryFn: async () => {
            const res = await fetcher(`/api/blogs?draft=${isDraft}`);
            return res.data
        }
    })

    return { allBlogsLoading, allBlogs, allBlogsRefetch };
};

export default useAllBlogs;