import { useMutation, useQueryClient } from "@tanstack/react-query";
import { fetcher } from "../utils/authFetch";

export const useUpdateHandicapData = () => {
  const queryClient = useQueryClient(); // Used to invalidate cache or refetch queries

  return useMutation({
    mutationFn: async ({ matchId, data }) => {
      const response = await fetcher.patch(`/api/handicap/v2/${matchId}`, data);
      return response?.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["handicap"]);
    },
  });
};