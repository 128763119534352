// import {
//   Button,
//   Card,
//   CardBody,
//   Chip,
//   Input,
//   Option,
//   Select,
// } from "@material-tailwind/react";
// import React, { useState } from "react";
// import toast from "react-hot-toast";
// import { ScrollRestoration } from "react-router-dom";
// import { fetcher } from "../../utils/authFetch";

// const AdminSoccer = () => {
//   const [open, setOpen] = useState(false);
//   const initialFormData = {
//     HomeTeam: "",
//     AwayTeam: "",
//     Date: "",
//     Time: "",
//     Venue: "",
//     LeagueName: "",
//     Prediction: "",
//     PredictedOdds: 0,
//     // "%Prediction": "15%",
//     Results: "",
//   };
//   const [formData, setFormData] = useState(initialFormData);

//   const [loading, setLoading] = useState(false);

//   const handleInputChange = (e) => {
//     const { name, value, type } = e.target;
//     setFormData({
//       ...formData,
//       [name]: type === "number" ? parseInt(value) : value,
//     });
//   };
//   const handleResultSelectChange = (value) => {
//     setFormData({
//       ...formData,
//       Results: value,
//     });
//   };

//   const formSubmitHandler = async (e) => {
//     e.preventDefault();

//     try {
//       setLoading(true);
//       setFormData(initialFormData);
//       const { data } = await fetcher.post("/api/soccer", { fields: formData });
//       toast.success("Game is added...");
//     } catch (error) {
//       setLoading(false);
//       toast.error("Not submitted", error);
//     }
//   };

//   const clearData = () => {
//     setFormData(initialFormData);
//   };

//   return (
//     <div className="">
//       <Chip
//         variant="ghost"
//         color="blue"
//         size="lg"
//         value="Soccer Matches"
//         className="max-w-min tracking-widest"
//         icon={
//           <span className="mx-auto mt-2 block h-2 w-2 rounded-full bg-blue-900 content-['']" />
//         }
//       />
//       <div className="my-6">
//         <Card className="bg-[#f0f3f8]">
//           <form onSubmit={formSubmitHandler}>
//             <CardBody>
//               <p>Create Match</p>
//               <p className="border-b border-gray-400 my-4"></p>
//               <div className="mt-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  gap-8 ">
//                 <div>
//                   <p className="text-black text-sm mb-2">
//                     Team 1 <span className="text-red-500">*</span>
//                   </p>
//                   <Input
//                     type="text"
//                     variant="static"
//                     placeholder="Enter Team 1"
//                     name="HomeTeam"
//                     value={formData.HomeTeam}
//                     onChange={handleInputChange}
//                     className="font bg-white shadow-2xl border-none rounded-md px-2 pt-[6px]"
//                   />
//                 </div>
//                 <div>
//                   <p className="text-black text-sm mb-2">
//                     Team 2 <span className="text-red-500">*</span>
//                   </p>
//                   <Input
//                     type="text"
//                     variant="static"
//                     name="AwayTeam"
//                     placeholder="Enter Team 2"
//                     value={formData.AwayTeam}
//                     onChange={handleInputChange}
//                     className="font bg-white shadow-2xl border-none rounded-md px-2 pt-[6px]"
//                   />
//                 </div>
//                 <div>
//                   <p className="text-black text-sm mb-2">
//                     Date <span className="text-red-500">*</span>
//                   </p>
//                   <Input
//                     type="date"
//                     variant="static"
//                     name="Date"
//                     value={formData.Date}
//                     onChange={handleInputChange}
//                     className="font bg-white shadow-2xl border-none rounded-md px-2 pt-[6px]"
//                   />
//                 </div>
//                 <div>
//                   <p className="text-black text-sm mb-2">
//                     Start Time <span className="text-red-500">*</span>
//                   </p>
//                   <Input
//                     type="time"
//                     variant="static"
//                     name="Time"
//                     value={formData.Time}
//                     onChange={handleInputChange}
//                     className="font bg-white shadow-2xl border-none rounded-md px-2 pt-[6px]"
//                   />
//                 </div>
//                 <div>
//                   <p className="text-black text-sm mb-2">Venue</p>
//                   <Input
//                     type="text"
//                     variant="static"
//                     placeholder="Enter The Venue"
//                     name="Venue"
//                     value={formData.Venue}
//                     onChange={handleInputChange}
//                     className="font bg-white shadow-2xl border-none rounded-md px-2 pt-[6px]"
//                   />
//                 </div>
//                 <div>
//                   <p className="text-black text-sm mb-2">Event</p>
//                   <Input
//                     type="text"
//                     placeholder="Enter League Name"
//                     name="LeagueName"
//                     value={formData.LeagueName}
//                     onChange={handleInputChange}
//                     variant="static"
//                     className="font bg-white shadow-2xl border-none rounded-md px-2 pt-[6px]"
//                   />
//                 </div>
//                 <div>
//                   <p className="text-black text-sm mb-2">We Predict</p>
//                   <Input
//                     type="text"
//                     placeholder="Enter Prediction"
//                     variant="static"
//                     name="Prediction"
//                     value={formData.Prediction}
//                     onChange={handleInputChange}
//                     className="font bg-white shadow-2xl border-none rounded-md px-2 pt-[6px]"
//                   />
//                 </div>
//                 {/* <div>
//                   <p className="text-black text-sm mb-2">% Prediction</p>
//                   <Input
//                     type="text"
//                     placeholder="15%"
//                     readOnly
//                     name="PercentPrediction"
//                     value={formData.PercentPrediction}
//                     onChange={handleInputChange}
//                     variant="static"
//                     className="font bg-white shadow-2xl border-none rounded-md px-2 pt-[6px]"
//                   />
//                 </div> */}
//                 <div>
//                   <p className="text-black text-sm mb-2">
//                     Odds <span className="text-red-500">*</span>
//                   </p>
//                   <Input
//                     type="number"
//                     placeholder="Enter Odds"
//                     name="PredictedOdds"
//                     value={formData.PredictedOdds}
//                     onChange={handleInputChange}
//                     variant="static"
//                     className="font bg-white shadow-2xl border-none rounded-md px-2 pt-[6px]"
//                   />
//                 </div>
//                 <div>
//                   <p className="text-black text-sm mb-2">
//                     Result <span className="text-red-500">*</span>
//                   </p>
//                   <Select
//                     label="Select result"
//                     animate={{
//                       mount: { y: 0 },
//                       unmount: { y: 25 },
//                     }}
//                     name="Results"
//                     value={formData.Results}
//                     onChange={handleResultSelectChange}
//                     className="font bg-white shadow-2xl  rounded-md "
//                   >
//                     <Option value="Win" className="bg-tansparent">
//                       Win
//                     </Option>
//                     <Option value="Loss" className="bg-tansparent">
//                       Loss
//                     </Option>
//                     <Option value="Draw" className="bg-tansparent">
//                       Draw
//                     </Option>
//                   </Select>
//                 </div>
//               </div>
//               <p className="border-t border-gray-400 my-8"></p>
//               <Button type="submit" className="capitalize bg-green-500">
//                 {loading ? "Creating" : " Create"}
//               </Button>
//               <Button
//                 onClick={clearData}
//                 className="capitalize bg-red-500 ml-4"
//               >
//                 Cancel
//               </Button>
//             </CardBody>
//           </form>
//         </Card>
//       </div>
//       <ScrollRestoration />
//     </div>
//   );
// };

// export default AdminSoccer;
import { Card, CardBody, Chip } from "@material-tailwind/react";
import React, { useEffect, useMemo, useState } from "react";
import { useAllMatches } from "../../hooks/useAllMatches";
import { format } from "date-fns";
import { getTimeUntilMatchStart } from "../../utils/getTimeUntilMatchStart";
import { useParams, useSearchParams } from "react-router-dom";
import Loading from "../../components/shared/Loading/Loading";
import SoccerCard from "../../components/soccer/SoccerCard";
import CricketCardV2 from "../../components/cricket/CricketCardV2";
import ValorantCardV2 from "../../components/valorant/ValorantCardV2";

const navList = [
  {
    name: "All",
    param: "all",
  },
  {
    name: "Live",
    param: "live",
  },
  {
    name: "Finished",
    param: "finished",
  },
  {
    name: "Schedule",
    param: "schedule",
  },
];

const AdminSoccer = () => {
  const {game} = useParams();

  // console.log(gamename);

  // const [searchParams] = useSearchParams();
  // const game = searchParams.get("game");

  const [filter, setFilter] = React.useState("all");
  const [date, setDate] = React.useState(new Date());

  const { data, isLoading } = useAllMatches({ date, filter, game });

  const handleFilterClick = (filterParam) => {
    setFilter(filterParam);
  };

  const [timeUntilMatchStarts, setTimeUntilMatchStarts] = useState([]);
  useEffect(() => {
    if (data?.data?.length > 0) {
      const timeUntilMatches = data.data.map((game) => {
        const { hours, minutes } = getTimeUntilMatchStart(
          game.fields.Date,
          game.fields.Time
        );
        game.fields.startHours = hours;
        game.fields.startMinutes = minutes;
        return { id: game.id, hours, minutes };
      });

      setTimeUntilMatchStarts(timeUntilMatches);
    }
  }, [data]);

  // Group matches by date and sort by time
  const groupedData = useMemo(() => {
    const groupedMatches = data?.data?.reduce((groups, item) => {
      const date = item.fields.Date;
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(item);
      return groups;
    }, {});

    // Sort each group by time
    for (const date in groupedMatches) {
      groupedMatches[date].sort((a, b) => {
        const timeA = new Date(`${a.fields.Date}T${a.fields.Time}`);
        const timeB = new Date(`${b.fields.Date}T${b.fields.Time}`);
        return timeA - timeB;
      });
    }

    return groupedMatches;
  }, [data]);

  // Get sorted dates
  const sortedDates = useMemo(() => {
    if (!groupedData) return [];
    return Object.keys(groupedData).sort((a, b) => new Date(a) - new Date(b));
  }, [groupedData]);

  return (
    <>
      <Card className="bg-lightPrimary dark:bg-primary h-full py-10">
        <CardBody className="overflow-y-scroll scroll-hidden text-justify text-gray-200 p-0">
          <div className="flex md:flex-row flex-col justify-between items-start gap-4 sticky top-0 bg-lightPrimary dark:bg-primary p-4  md:py-0 md:px-6 rounded-xl z-20">
            <div className="overflow-y-scroll scroll-hidden text-justify text-gray-200">
              <div className="flex justify-between items-center ">
                <div className="flex gap-2 ">
                  {navList.map((item, i) => (
                    <button
                      key={i}
                      className={`text-[10px] py-1 px-2.5 rounded-lg ${
                        filter === item.param
                          ? "bg-primaryLighter text-black"
                          : "bg-lightSecondary dark:bg-secondary dark:text-white text-black"
                      }`}
                      onClick={() => handleFilterClick(item.param)}
                    >
                      {item.name}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {isLoading ? (
            <Loading className="h-40 " />
          ) : (
            <div className="md:px-6 px-1 md:pb-6 pb-4">
              {sortedDates.length > 0 ? (
                sortedDates.map((date, i) => (
                  <div key={i}>
                    <Chip
                      variant="filled"
                      className="max-w-min mt-12 bg-lightSecondary dark:bg-secondary text-black dark:text-white"
                      value={`Date: ${format(new Date(date), "dd MMMM, yyyy")}`}
                    />
                    <div className="mt-4 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-5">
                      {groupedData[date].map((item, j) => (
                        <div key={j}>
                          {item?.fields?.gameType === "Soccer" ? (
                            <SoccerCard data={item} />
                          ) : item?.fields?.gameType === "Cricket" ? (
                            <CricketCardV2 data={item} />
                          ) : (
                            <ValorantCardV2 data={item} />
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                ))
              ) : (
                <p className="dark:text-lightPrimary text-black text-center pt-20">
                  No matches are available.
                </p>
              )}
            </div>
          )}
        </CardBody>
      </Card>
    </>
  );
};

export default AdminSoccer;
