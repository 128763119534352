import React from "react";
import { FaRegStar, FaStar } from "react-icons/fa";
import SoccerPrediction from "./SoccerPrediction";
import SoccerMatchDetails from "./SoccerMatchDetails";
import { isEligible } from "../../utils/isEligible";
import UpdatePlanModal from "../shared/UpdatePlanModal";
import { useLocation } from "react-router-dom";
import SoccerPredictionEditModal from "./SoccerPredictionEditModal";

const SoccerCard = ({ data }) => {
  const [predictionOpen, setPredictionOpen] = React.useState(false);
  const [predictionEditOpen, setPredictionEditOpen] = React.useState(false);
  const [matchDetailsOpen, setMatchDetailsOpen] = React.useState(false);
  const [matchDetailsData, setMatchDetailsData] = React.useState();
  const [predictionData, setPredictionData] = React.useState();
  const [notEligibleOpen, setNotEligibleOpen] = React.useState(false);
  const location = useLocation();
  const isAdminPath = location.pathname.split("/")[1] === "admin";
  const handlePrediction = (data) => {
    const eligible = isEligible(data);

    if (eligible) {
      setPredictionData(data?.fields);
      setPredictionOpen(true);
    } else {
      setNotEligibleOpen(true);
    }
  };

  const handleEditPredictionModal = (data) => {
    setPredictionData(data);
    setPredictionEditOpen(true);
  };

  const handleMatchDetails = (data) => {
    setMatchDetailsData(data);
    setMatchDetailsOpen(true);
  };
  console.log(data?.fields);
  return (
    <>
      <div className="w-full bg-lightSecondary dark:bg-lightPrimary/10 text-secondary dark:text-lightSecondary min-h-[20vh] rounded-lg px-4 py-2">
        {/* league name and  */}
        <div className="flex justify-between items-center bg-primary/10 dark:bg-secondary/30 py-3 px-2 rounded-md">
          <div className="flex gap-2 items-center">
            <img
              src={data?.fields?.Tournament_logo}
              alt="soccer"
              className="w-4 h-4"
            />
            <p className="text-xs font-semibold">{data?.fields?.LeagueName}</p>
          </div>
          <p className="text-xs font-bold bg-lightPrimary text-secondary px-2 rounded">
            {data?.fields?.gameType}
          </p>
        </div>

        {/* team name and logo section*/}
        <div className="mt-8 flex justify-between">
          <div className="flex gap-2 items-center">
            <img src={data?.fields?.T1Logo} alt="soccer" className="w-4 h-4" />
            <p className="text-sm font-semibold">
              {data?.fields?.HomeTeam?.length > 15
                ? data?.fields?.HomeTeam.slice(0, 15) + "..."
                : data?.fields?.HomeTeam}
            </p>
          </div>
          <p className="font-semibold text-orange-600">VS</p>
          <div className="flex gap-2 items-center">
            <img src={data?.fields?.T2Logo} alt="soccer" className="w-4 h-4" />
            <p className="text-sm font-semibold">
              {data?.fields?.AwayTeam?.length > 15
                ? data?.fields?.AwayTeam.slice(0, 15) + "..."
                : data?.fields?.AwayTeam}
            </p>
          </div>
        </div>

        {/* status and time and date */}
        <div className="mt-4 flex justify-between">
          <div className="flex gap-2 items-center">
            {data?.fields?.status === "Upcoming" ? (
              <FaRegStar className="text-black dark:text-white" />
            ) : (
              <FaStar
                className={`${
                  data?.fields?.Results === "FALSE"
                    ? "text-red-600"
                    : "text-green-600"
                }`}
              />
            )}
            <p
              className={`text-sm font-semibold ${
                data?.fields.status === "Finished"
                  ? "text-orange-600"
                  : data.fields.status === "Upcoming"
                  ? "text-green-600"
                  : "text-red-600"
              }`}
            >
              {data?.fields?.status}
            </p>
          </div>
          <p className="text-sm">
            {data?.fields?.Date} | {data?.fields?.Time}
          </p>
        </div>
        <div className="mt-4">
          <div className="text-green-600 text-center">Odds</div>
          <div className="grid grid-cols-3 gap-2 mt-2 text-[13px]">
            <div className="flex flex-wrap justify-between items-center bg-[#C7CEDF] dark:bg-[#1B254B] px-2 py-1 rounded">
              <div>{data?.fields?.HomeTeam}</div>
              <div>{data?.fields?.HomeOdds}</div>
            </div>
            <div className="flex flex-wrap justify-between items-center bg-[#C7CEDF] dark:bg-[#1B254B] px-2 py-1 rounded">
              <div>Draw</div>
              <div>{data?.fields?.DrawOdds}</div>
            </div>
            <div className="flex flex-wrap justify-between items-center bg-[#C7CEDF] dark:bg-[#1B254B] px-2 py-1 rounded">
              <div>{data?.fields?.AwayTeam}</div>
              <div>{data?.fields?.AwayOdds}</div>
            </div>
          </div>
        </div>

        {/* start in hours */}
        {(data?.fields?.startHours || data?.fields?.startMinutes) && (
          <div className="mt-8 flex justify-center items-center bg-primary/10 dark:bg-[#171b4e] py-2 rounded-md">
            {data.fields.startHours > 0 || data.fields.startMinutes > 0 ? (
              <p className="text-center text-sm font-semibold">
                {data.fields.startHours} hrs {data.fields.startMinutes} mins to
                start
              </p>
            ) : data.fields.Results ? (
              <p className="text-center text-sm font-semibold">
                Match has already finished
              </p>
            ) : (
              <p className="text-center text-sm font-semibold">
                Match has already started
              </p>
            )}
          </div>
        )}
        <div className="card mx-2 my-5 ">
            <h4 className="text-sm lg:text-xl text-center text-black dark:text-white">
              We Predict :{" "}
              <strong className="font-bold tracking-widest text-green-800 dark:text-green-500">
                {data?.fields?.Prediction}
              </strong>
            </h4>
          </div>
        {/* button section */}
        <div className="mt-6 flex justify-between gap-2">
          {/* <button
            className="w-full border border-secondary/50 hover:bg-secondary hover:text-lightSecondary dark:border-lightPrimary/20 dark:hover:bg-lightPrimary dark:hover:text-secondary text-xs font-semibold uppercase py-1.5 rounded-lg"
            onClick={() => handlePrediction(data)}
          >
            Get prediction
          </button> */}

          <button
            className="w-full border border-secondary/50 hover:bg-secondary hover:text-lightSecondary dark:border-lightPrimary/20 dark:hover:bg-lightPrimary dark:hover:text-secondary text-xs font-semibold uppercase py-1.5 rounded-lg"
            onClick={() => handleMatchDetails(data?.fields)}
          >
            MatCh Details
          </button>
          {isAdminPath && (
            <button
              className="w-full border border-secondary/50 hover:bg-secondary hover:text-lightSecondary dark:border-lightPrimary/20 dark:hover:bg-lightPrimary dark:hover:text-secondary text-xs font-semibold uppercase py-1.5 rounded-lg"
              onClick={() => handleEditPredictionModal(data?.fields)}
            >
              Edit
            </button>
          )}
        </div>
      </div>
      <SoccerPrediction
        open={predictionOpen}
        handleOpen={() => setPredictionOpen(!predictionOpen)}
        data={predictionData}
      />
      <SoccerMatchDetails
        open={matchDetailsOpen}
        handleOpen={() => setMatchDetailsOpen(!matchDetailsOpen)}
        data={matchDetailsData}
      />

      <UpdatePlanModal
        open={notEligibleOpen}
        handleOpen={() => setNotEligibleOpen(!notEligibleOpen)}
      />
      <SoccerPredictionEditModal
        open={predictionEditOpen}
        handleOpen={() => setPredictionEditOpen(!predictionEditOpen)}
        setPredictionEditOpen={setPredictionEditOpen}
        data={predictionData}
      />
    </>
  );
};

export default SoccerCard;
